<script setup>
import { toRefs, computed } from 'vue'
import { usePage } from '@inertiajs/inertia-vue3'
import usePushNotifications from './Composables/usePushNotifications.js'
import TextInput from '@/Shared/TextInput.vue'
import Logo from '@/Shared/Logo.vue'

const props = defineProps({
  secret: Array,
})

const flash = computed(() => usePage().props.value.flash)
const { secret } = toRefs(props)

const { showbannerNoti, form, start, registerSuscribtion, registerForm } = usePushNotifications(secret.value)

start()

</script>

<template>
  <div class="relative h-screen">
    <div class="absolute top-0 w-full">
      <div v-if="showbannerNoti" class="bg-green-600 banner-noti-on">
        <div class="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="flex flex-wrap items-center justify-between">
            <div class="flex items-center justify-center flex-1">
              <span class="flex p-2 bg-green-800 rounded-lg">
                <svg
                  class="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  />
                </svg>
              </span>
              <p class="ml-3 font-medium text-white truncate ">
                <span class="text-center"> Notifications are now enabled for this device. </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="bg-red-600 banner-noti-off">
        <div class="px-3 py-3 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div class="flex flex-wrap items-center justify-between">
            <div class="flex items-center flex-1 ">
              <span class="flex p-2 bg-red-800 rounded-lg">
                <!-- Heroicon name: outline/speakerphone -->
                <svg
                  class="w-6 h-6 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 5.882V19.24a1.76 1.76 0 01-3.417.592l-2.147-6.15M18 13a3 3 0 100-6M5.436 13.683A4.001 4.001 0 017 6h1.832c4.1 0 7.625-1.234 9.168-3v14c-1.543-1.766-5.067-3-9.168-3H7a3.988 3.988 0 01-1.564-.317z"
                  />
                </svg>
              </span>
              <p class="ml-3 font-medium text-white truncate">
                <span class="inline md:hidden"> Notifications have been enabled. </span>
                <span class="hidden md:inline"> Notifications are disabled. Please enable and allow push notifications. </span>
              </p>
            </div>
            <div class="flex-shrink-0 order-3 w-full mt-2 sm:order-2 sm:mt-0 sm:w-auto">
              <button class="flex items-center justify-center px-4 py-2 text-sm font-medium text-red-600 bg-white border border-transparent rounded-md shadow-sm hover:bg-red-50 btn-noti-off" @click="registerSuscribtion">
                Allow
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid h-full grid-cols-12">
      <div class="flex flex-col items-center justify-center h-full col-span-12 lg:col-span-6 ">
        <img
          src="@/images/logo.png"
          alt="Hartland Logo"
          class="block h-24 mx-auto lg:h-32 xl:h-32"
        >
        <div class="w-1/2 px-5 mt-6 ">
          <form
            class="mt-3 space-y-6"
            @submit.prevent="registerForm"
          >
            <div class="w-full h-8 mb-6">
              <div v-if="flash.error" class="px-2 py-2 bg-red-100 rounded-md">
                <span class="block text-sm font-medium text-red-700">
                  {{ flash.error }}
                </span>
              </div>
              <div v-else-if="flash.success" class="px-2 py-2 bg-green-100 rounded-md">
                <span class="block text-sm font-medium text-green-800">
                  {{ flash.success }}
                </span>
              </div>
            </div>
            <TextInput
              v-model="form.push.name"
              label="Name"
              required
              :errors="form.errors.name"
            />
            <TextInput
              v-model="form.push.email"
              label="Email"
              required
              :errors="form.errors.email"
            />
            <button v-if="showbannerNoti" class="w-full px-8 py-2 mt-3 button-rounded button-primary" :disabled="form.processing">
              Register
            </button>
          </form>
          <button
            v-if="!showbannerNoti"
            type="button"
            class="w-full px-8 py-2 mt-3 button-rounded button-primary"
            @click="registerSuscribtion"
          >
            Enable notifications
          </button>
        </div>
        <div class="flex items-center mx-auto mt-20">
          <p class="font-semibold text-gray-500">
            By
          </p>
          <Logo
            version="iq-gray"
            class="block h-4 ml-1"
          />
        </div>
      </div>
      <div class="hidden lg:block lg:col-span-6">
        <img
          class="object-cover object-left w-full h-full ml-auto"
          src="@/images/push_bg.png"
          alt="Background"
        >
      </div>
    </div>
  </div>
</template>